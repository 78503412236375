<template>
  <div class="add-new-holiday">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.skillCreate')"
      :title="$t('messages.skillCreate')"
      @submitPressed="submitPressed"
    />
    <skills-form :skillObject="skill" :action="action" @clearAction="action = null"/>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import SkillsForm from './components/SkillsForm.vue'

export default {
  components: {
    TitleBar,
    SkillsForm
  },
  data() {
    return {
      action: null,
      skill: {
        
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
}
</script>
